import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/admin/store/page';
import store from '@/admin/store';
import { deleteImportCard, getImportsTable, getImportStatuses, getParsersTypes } from '@/admin/api/import';
import { formatCreatedAt, generateSort, getApiErrorMessage, truncateString } from '@/lib/utils/Utils';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import { processListForSelect } from '@/lib/utils/form';
import {
    FilterDateParamsInterface,
    FilterRadioParamsInterface,
    FilterSelectParamsInterface,
} from '@/lib/layouts/page/filterParams.interface';
import { Filter } from '@/lib/layouts/page/filter.interface';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import filterModel from './filter';
import ImportFilter from './filterEntity';
import { YesNoMap } from '@/constants';

const MODULE_NAME = 'settingsImport';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsImport extends PageBaseModule {
    filter: Filter;
    statuses: { id: string; name: string }[];

    constructor(module: SettingsImport) {
        super(module);

        this.statuses = [];

        const page = new PageEntity();
        this.pageSettings = page.values;

        const filter = new filterModel();
        this.filter = getModule(ImportFilter);
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterName('ImportFilter');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_STATUSES(list: { id: string; name: string }[]): void {
        this.statuses = list;
    }

    @Action({ rawError: true })
    async init() {
        await this.initStatuses();
        this.getTable();
    }

    @Action({ rawError: true })
    async initTypes() {
        return processListForSelect(await getParsersTypes());
    }

    @Action({ rawError: true })
    async initStatuses(): Promise<void> {
        this.SET_STATUSES(await getImportStatuses());
    }

    @Action({ rawError: true })
    async updateSelect(params: FilterSelectParamsInterface) {
        await this.filter.updateSelect(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateStartDate(params: FilterDateParamsInterface) {
        await this.filter.updateStartDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateEndDate(params: FilterDateParamsInterface) {
        await this.filter.updateEndDate(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async updateRadio(params: FilterRadioParamsInterface) {
        await this.filter.updateRadio(params);
        this.filter.updateFilter();
    }

    @Action({ rawError: true })
    async getTable() {
        this.SET_IS_LOADING(true);

        const result = await getImportsTable(
            this.pageSettings.pageCurrent,
            await generateSort({ id: 'file', value: 'ASC' }),
            '',
            +this.pageSettings.pageAmountItems,
        );

        const table = await this.prepareTable(result);
        await this.SET_TABLE(table);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async deleteImport(id: number) {
        try {
            const result = await deleteImportCard(id);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Задача на импорт удалена', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'ok' });
            }

            this.getTable();
        } catch (error) {
            ResponseHandlerModule.showNotify({ message: await getApiErrorMessage(error), type: 'fail' });
        }
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        let hiddenColumns = Object.values(table.titles)
            .filter((title) => title.visible === false)
            .map((title) => title.id);
        hiddenColumns = [...hiddenColumns, ...['sellerId']];

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'dateTimeStart') {
                    cell = cell ? formatCreatedAt(cell) : '-';
                }

                if (i === 'dateTimeEnd') {
                    cell = cell ? formatCreatedAt(cell) : '-';
                }

                if (i === 'publish') {
                    cell = YesNoMap.get(cell);
                }

                if (i === 'status') {
                    cell = this.statuses.filter((status) => status.id === cell)[0].name || cell;
                }

                if (i === 'auctions') {
                    cell = cell && cell.length === 0
                        ? '-'
                        : cell.map((auction: {id: number, name: string}) => `
                        <a href="/auctions/auction/${auction.id}" class="cp-table__item-link">
                            <span class="cp-table-column__accent">${auction.name}</span>
                        </a>
                    `).join('');
                }

                if (i === 'log') {
                    cell = cell ? truncateString(cell, 50) : '';
                }

                rowProcessed[i] = cell;
            }

            rowProcessed['_actions'] = ['delete'];

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }
}
export default getModule(SettingsImport);
